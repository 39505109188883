.coin-container .content {
    max-width: 740px;
    margin: 1rem auto;
    padding: 0.7rem 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
}

.rank {
    margin: 0.5 0;
}

.rank-btn {
    border: 1px solid #6900ff;
    box-shadow: 0px 0px 8px #6900ff;
    background-color: #6900ff;
    border-radius: 8px;
    padding: 0.2rem;
}

.info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.info .coin-heading {
    display: flex;
    align-items: center;
    margin: 1rem 0;
}

.info .coin-price {
    display: flex;
    align-items: center;
    justify-content: center;
}

.info p {
    padding-right: 1rem;
}

table {
    margin: 0.5rem 0;
}

td, th {
    padding: 8px;
    text-align: center;
}

th {
    background-color: #333;
}

.stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    width: 100%;
}

.stats .row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #808080;
    margin: 0.6rem 0;
    padding-bottom: 0.5rem;
}

.stats .row p:first-child {
    color: #d3d3d3;
}

.about h3 {
    margin: 1rem 0;
}

@media screen and (max-width: 700px) {
    .coin-container .content {
        max-width: 100%;
        margin: 0.5rem;
        padding: 0 0.5rem;
    }

    .stats {
        grid-template-columns: 1fr;
    }

    td, th {
        padding: 3px;
    }

    .rank {
        margin: 0.5rem;
    }

    .rank-btn {
        margin: 0.5rem 0;
    }
}

.top-coin {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color:#26272b;
    box-shadow: 0px 0px 12px #18191b;
}

.top-coin-info {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.coin-logo {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    object-fit: cover;
}

.coin-details {
    display: flex;
    flex-direction: column;
}

.price-down {
    color: red;
}

.price-up {
    color: green;
}

@media screen and (max-width: 768px) {
    .top-coin {
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .coin-logo {
        width: 60px;
        height: 60px;
        margin-bottom: 10px;
    }

    .coin-details {
        align-items: center;
        text-align: center;
    }
}

.blue-button {
    background-color: var(--blue);
    color: white;
    padding: 5px 10px;
    margin: 5px 0;
    display: inline-flex;
    align-items: center;
    border: none;
    cursor: pointer;
}

.blue-button svg {
    margin-right: 2px;
}

.coin-container .content .action-buttons {
    display: flex;
    flex-direction: column;
}

.coin-container .content .coin-price {
    display: flex;
    flex-direction: column;
}

.coin-container .content .coin-price .blue-button {
    margin-top: 10px;
}
