.container {
    max-width: 1140px;
    margin: auto;
}

.heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #26272b;
    box-shadow: 0px 0px 12px #18191b;
    border-radius: 8px;
    margin: 2rem 1rem;
    padding: .7rem 1rem;
    font-weight: 700;
}

.coin-name {
    margin-left: -4rem;
}

.coin-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #26272b;
    box-shadow: 0px 0px 12px #18191b;
    border-radius: 8px;
    margin: 2rem 1rem;
    padding: .7rem 1rem;
}

.coin-row:hover {
    transform: scale(1.04);
    transition: .3s ease-in-out;
    cursor: pointer;
}

img {
    height: 40px;
    margin-right: 8px;
    width: auto;
}

.img-symbol {
    display: flex;
    align-items: center;
}

.top-coins-container {
    margin-top: 20px;
}

.top-coins {
    display: flex;
    justify-content: space-between;
}

.top-coin {
    width: 30%;
    background-color: #b3bdb6;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.coin-logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.coin-details {
    flex: 1;
}

.price-down {
    color: red;
}

.price-up {
    color: green;
}

@media screen and (max-width: 720px) {
    .hide-mobile {
        display: none;
    }
}

.search-bar {
    margin: 20px;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
  }
  
  .pagination button {
    background-color: #26272b;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pagination button:disabled {
    background-color: #888;
    cursor: not-allowed;
  }