.header {
    text-align: center;
    max-width: 1140px;
    margin: auto;
  }
  
  .header h1 {
    margin-bottom: 10px;
    max-width: 1140px;
    margin: auto;
  }
  
  .header p {
    margin-bottom: 5px;
    max-width: 1140px;
    margin: auto;
  }
  