@import url("https://fonts.googleapis.com/css2?family=Sora:wght@300;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Reset styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary: #ffffff;
  --background: #030922;
  --dark-background: #02071b;
  --input-background: #091030;
  --roadmap-icon: #6b81d9;
  --text-blue: #2499f9;
  --blue: #1457ed;
  --green: #50fc61;
  --red: #ff3333;
  --subdue-button: #021338fa;
  --font-sora: "Sora", sans-serif;
  --font-code: "Source Code Pro", monospace;
  --font-grotesk: "Space Grotesk", sans-serif;
  color-scheme: dark;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden; /* Prevent horizontal scrolling */

}

body {
  background-color: var(--background);
  color: var(--primary);
  font-family: var(--font-sora), -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style-type: none;
}

ul > li {
  cursor: pointer;
}

a {
  color: var(--primary);
  text-decoration: none;
}

nav, section {
  margin: 0 5rem;
  margin-bottom: 8rem;
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
  nav, section {
    margin: 0 1rem;
    margin-bottom: 2rem;
  }
  .flex {
    flex-direction: column;
  }
}

.blue {
  color: var(--text-blue);
}

.subdue {
  opacity: 0.7;
}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.a-center {
  align-items: center;
}

.a-start {
  align-items: flex-start;
}

.j-center {
  justify-content: center;
}

.j-between {
  justify-content: space-between;
}

.gap {
  gap: 4rem;
}

.gap-half {
  gap: 0.5rem;
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

.text-center {
  text-align: center;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2rem;
}

p {
  font-size: 1.2rem;
  word-spacing: 0.2rem;
  line-height: 1.8rem;
}

.half-width {
  height: 80vh;
}

/* About Us Page Styles */
.about-us-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.hero-section {
  padding: 100px 0;
  text-align: center;
  background-color: #091030;
}

.hero-title {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.hero-description {
  font-size: 1.2rem;
}

.mission-section {
  padding: 80px 0;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.section-description {
  font-size: 1.2rem;
  line-height: 1.5;
}

.values-grid, .team-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.value, .team-member {
  text-align: center;
}

.value {
  background-color: #091030;
  padding: 2rem;
  border-radius: 0.5rem;
}

.value-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.value-description {
  font-size: 1.2rem;
}

.team-member img {
  width: 100%;
  border-radius: 50%;
}

.team-member-name {
  margin-top: 20px;
  font-size: 20px;
}

.team-member-role {
  font-size: 16px;
}

.links {
  margin-top: 2rem;
}

.compliance-link {
  display: block;
  color: #2499f9;
}

.compliance-image img {
  width: 100%;
  border-radius: 0.5rem;
}

.rotate-45 {
  @apply rotate-[45deg]
}

.rotate-90 {
  @apply rotate-[90deg]
}

.rotate-135 {
  @apply rotate-[135deg]
}

.rotate-180 {
  @apply rotate-[180deg]
}

.rotate-225 {
  @apply rotate-[225deg]
}

.rotate-270 {
  @apply rotate-[270deg]
}

.rotate-315 {
  @apply rotate-[315deg]
}

.rotate-360 {
  @apply rotate-[360deg]
}

.-rotate-45 {
  @apply rotate-[-45deg]
}

.-rotate-90 {
  @apply rotate-[-90deg]
}

.-rotate-135 {
  @apply rotate-[-135deg]
}

.-rotate-180 {
  @apply rotate-[-180deg]
}

.-rotate-225 {
  @apply rotate-[-225deg]
}

.-rotate-270 {
  @apply rotate-[-270deg]
}

.-rotate-315 {
  @apply rotate-[-315deg]
}

.-rotate-360 {
  @apply rotate-[-360deg]
}
