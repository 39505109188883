.top-coins-wrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.toggle-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.toggle-buttons button {
    background-color: #f0f0f0;
    color: #333;
    border: 1px solid #ddd;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
}

.toggle-buttons button.active {
    background-color: #007bff;
    color: white;
    border: none;
}

.header {
    padding: 20px;
    background-color: var(--root-background-color);
    border-radius: 10px;
    text-align: center;
    width: 100%;
    max-width: 800px;
}

.header h2 {
    margin-bottom: 10px;
}

.header p {
    margin-bottom: 20px;
}

.see-more-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.see-more-button:hover {
    background-color: #0056b3;
}

.top-coins {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 800px;
}

.top-coins .coin-card {
    width: calc(30% - 20px); /* Adjust width as needed */
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 10px;
}

.top-coins .coin-card img {
    max-width: 50px;
}

.top-coins .coin-card .coin-details {
    margin-top: 10px;
}

@media screen and (max-width: 768px) {
    .top-coins-wrapper {
        padding: 10px;
    }

    .top-coins {
        flex-direction: column;
        align-items: center;
    }

    .top-coins .coin-card {
        width: 80%;
    }

    .header {
        width: 100%;
    }

    .toggle-buttons {
        flex-direction: column;
        align-items: center;
    }

    .toggle-buttons button {
        margin: 5px 0;
    }
}









